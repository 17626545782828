@import '../../../style/varibale';

.app__AboutUS_Home {
    margin-top: 100px; 
    .container_about_us {
        width: 72%;
        margin: auto;

        @media screen and (width < 1300px) {
            width: 95%;
        }
    }

    .item1,
    .item2,
    .item3 {

        .content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            width: 90%;
            height: 100%;

            h4 {
                color: $mainColor;
                font-family: Cairo;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 150.4%;

                @media screen and (width < 767px) {
                    text-align: center;
                }

            }

            p {
                color: $mainColor;
                font-family: Cairo;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media screen and (width < 767px) {
                    text-align: center;
                }
            }
        }


    }


    .item1 {
        background-color: $secondColor;

        h4 {
            @media screen and (width < 767px) {
                margin-left: 30px;
                margin-right: 30px;
                margin-top: 20px;
            }
        }

        p {
            @media screen and (width < 767px) {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

    }

    .item2 {
        background-color: $mainColor;

        .item2_sm {



            @media screen and (width < 767px) {
                display: flex;
                flex-direction: column-reverse !important;
            }
        }

        h4 {
            color: #fff !important;
            margin-left: 30px;
            margin-right: 30px;

        }

        p {
            color: #fff !important;
            margin-left: 30px;
            margin-right: 30px;

        }

        h4 {
            @media screen and (width < 767px) {
                margin-left: 30px;
                margin-top: 20px;
            }
        }

        p {
            @media screen and (width < 767px) {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

    }

    .item3 {
        background-color: $secondColor;

        h4 {
            @media screen and (width < 767px) {
                margin-left: 30px;
                margin-right: 30px;
                margin-top: 20px;
            }
        }

        p {
            @media screen and (width < 767px) {
                margin-left: 30px;
                margin-right: 30px;
            }
        }
    }

    .app__about_overlay {
        position: relative;

        .overlay {
            position: absolute;
            inset: 0;
              background: linear-gradient(180deg, rgba(148, 118, 62, 0.00) 0%, rgba($secondColor , 0.65) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%) no-repeat;
            // background: linear-gradient(180deg, rgba(15, 30, 67, 0.00) 0%, rgba(15, 30, 67, 0.75) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%) no-repeat;
            opacity: 1;
            transition: opacity 0.5s;
        }

        &:hover .overlay {
            opacity: 0;
            /* Show the overlay on hover */
        }
    }

    .app__about_overlay2 {
        .overlay {
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, rgba(15, 30, 67, 0.00) 0%, rgba(15, 30, 67, 0.75) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%) no-repeat;
        }
    }
}

.app__about_sm {
    @media screen and (width < 900px) {
        flex-direction: column-reverse !important;
    }
}

.about_us_home {
    .app__AboutUS_Home {
        margin-top: 100px; 
        .container_about_us {
            width: 76%;
            margin: auto;

            @media screen and (width < 1300px) {
                width: 95%;
            }
        }
    }
}