@import '../../../style/varibale';

.app__products {
    margin-top: 100px;
    width: 70% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (width < 767px) {
        width: 95% !important;
    }


}

.app__building {
    margin-top: 50px;

    .content {
        .header {
            display: flex;
            align-items: center;
            gap: 2px;

            h3 {
                color: $secondColorDark;
                font-family: Cairo;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-top: 17px;

                @media screen and (width < 767px) {
                    font-size: 20px;
                }
            }
        }

        .list {
            p {
                color: $mainColor;
                font-weight: 700;

                @media screen and (width<1025px) {
                    width: 100% !important; 
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;

                @media screen and (width < 767px) {
                    gap: 5px;
                }

                li {
                    color: var(--dark-blue, #2C2E35);

                    /* Comment */
                    font-family: Cairo;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media screen and (width < 1025px) {
                        font-size: 14px;

                    }
                }
            }
        }
    }

    .btn_ready {
        margin-top: 50px;

        @media screen and (width < 900px) {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }

        .ready {
            background-color: $mainColor;
            border-radius: 77px;
            box-shadow: 0px 4px 25px 0px rgba(161, 110, 13, 0.60);
            width: fit-content;
            width: 313px;
            height: 105px;
            flex-shrink: 0;
            cursor: pointer;
            transition: all 0.5s;

            @media screen and (width < 767px) {
                height: 85px;
            }

            &:hover {
                box-shadow: none;
            }
        }

        svg {
            @media screen and (width < 767px) {
                width: 40px;
            }
        }

        .content {
            h3 {
                color: var(--white, #FFF);
                font-family: Cairo;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;

                @media screen and (width < 767px) {
                    font-size: 35px;

                }


            }

            p {
                color: var(--white, #FFF);

                /* Caption */
                font-family: Cairo;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                /* 171.429% */
                letter-spacing: 0.14px;
            }
        }
    }

    .icons {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
    }

    .draw {
        padding: 80px;
        background-color: $mainColor;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;

        @media screen and (width < 767px) {
            padding: 10px;
            gap: 10px;

        }

        .top,
        .bottom {
            position: absolute;
            height: 82px;
            right: 200px;
            background-color: #FFF;
            width: 369px;

            @media screen and (width < 767px) {
                display: none;
            }
        }

        .bottom {
            bottom: 0;
        }

        .top {
            top: 0;
        }
    }
}

[dir="rtl"] .icons_details_project {
    transform: rotate(180deg);
}

[dir="rtl"] .btn_ready .ready .content h3 {
    font-size: 30px;
}

.name_place {
    position: relative;

    span {
        position: absolute;
        border-radius: 30px;
        background: $secondColorDark;
        top: 15px;
        color: $light;
        text-align: center;
        padding: 5px 10px;
        font-family: Cairo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.14px;
    }
}

[dir="ltr"] .name_place {
    span {
        right: 15px;
    }
}

[dir="rtl"] .name_place {
    span {
        left: 15px;
    }
}