@import '../../style/varibale';



.app__header {
    .main_page {
        width: 100%;
        height: 100vh;
        position: relative;

        @media screen and (width <1025px) {
            height: fit-content;
            margin-top: calc(93px + 64px) !important;
        }

        .left {
            position: absolute;
            left: 0;
            bottom: 0;
            @media screen and (width <767px) {
              img{
                width: 80px;
                height: 80px;
              }
            }
    
        }

        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;

            h1 {
                color: var(--white, #FFF);

                /* Heading 2 */
                font-family: Cairo;
                font-size: 64px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media screen and (width < 767px) {
                    font-size: 35px;
                }
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(44, 46, 53, 0.50) 0%, rgba(44, 46, 53, 0.00) 100%), rgba(17, 33, 66, 0.50);

        }
    }

}