@import '../../style/varibale';

.app__products_page {
    .app_header {
        h3 {
            color: $mainColor;

            /* Heading 3 */
            font-family: Cairo;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;

            /* 63px */
            @media screen and (width < 767px) {
                font-size: 30px;
            }

            @media screen and (width < 821px) {
                font-size: 30px;
            }
        }

        p {
            color: $dark;

            /* Body 2 */
            font-family: Cairo;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 70%;

            @media screen and (width < 767px) {
                font-size: 16px;
            }

            @media screen and (width < 821px) {
                width: 90%;
                font-size: 16px;

            }
        }
    }

    .item {
        margin-top: 50px;
        display: flex;
        flex-direction: row !important;
        align-items: left !important;

        gap: 18px;
        justify-content: start;

    }

    .app__product_section2 {
        margin-top: 100px;

        .app_header {
            h3 {
                @media screen and (width < 835px) {
                    font-size: 35px;
                    text-align: center;
                }

                @media screen and (width < 767px) {
                    font-size: 25px;
                }

                @media screen and (width < 600px) {
                    font-size: 20px;
                }
            }
        }

        .item_sec_2 {
            gap: 15px;
            padding: 25px;
            width: 90%;
            margin: auto;

            h3 {
                color: var(--main-blue, #0F1E43);
                text-align: center;
                font-family: Cairo;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

            }

            p {
                color: var(--dark-blue, #2C2E35);
                text-align: center;

                /* Caption */
                font-family: Cairo;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 171.429% */
                letter-spacing: 0.14px;
            }
        }

    }
}

.item_section_color {
    background-color: rgba($color: $secondColor, $alpha: 20%);

    .h3 {

        font-family: Cairo;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 150.4%;

        @media screen and (width < 1028px) {
            font-size: 20px;
        }

        @media screen and (width < 1000px) {
            font-size: 25px;
        }
    }

}

.item_section_color_sm {
    @media screen and (width < 985px) {
        background-color: rgba($color: $secondColor, $alpha: 20%);

    }
}

.item_section_color_dark {
    background-color: rgba($color: $secondColorDark, $alpha: 100%);

    .h3 {
        color: var(--white, #FFF);
        font-family: Cairo;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 150.4%;

        @media screen and (width < 1028px) {
            font-size: 20px;
        }

        @media screen and (width < 1000px) {
            font-size: 25px;
        }
    }

    .span {
        color: var(--dark-blue, #2C2E35);
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}



.app__Partners {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;

    .header {
        h3 {
            color: var(--dark, #393536);

            /* Heading 3 */
            font-family: Cairo;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            /* 63px */
        }

        p {
            color: var(--grey, #929292);

            /* Comment */
            font-family: Cairo;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
            line-height: normal;
        }
    }

    .item_client {

        margin-top: 20px;
        border-radius: 96.004px;
        background: var(--white, #FFF);
        width: 100px !important;
        height: 31.458px !important;
        box-shadow: 0px 2.50888px 25px 0px rgba(0, 0, 0, 0.10), 0px 4px 25px 0px rgba(15, 30, 67, 0.10);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            flex-shrink: 0;
            padding: 5px 10px;

        }
    }
}