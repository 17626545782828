@import '../../../style/varibale';

.app_products {
    margin-top: 100px;

    .p-button {
        gap: 20px;
        display: flex;
        flex-direction: row-reverse;
        border-radius: 25px;
        background: $secondColorDark !important;
        border: $secondColorDark !important;
        padding: 15px 40px;

        .p-button-label {
            color: $light;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .p-button:enabled:hover,
    .p-button:not(button):not(a):not(.p-disabled):hover {
        background: linear-gradient(270deg, #d6c485 0%, #0F1E43 106.49%) !important;

    }

    h2 {
        color: $mainColor;
        font-family: Cairo;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        @media screen and (width < 811px) {
            font-size: 30px; 
        }
    }

    .item {
        margin-top: 50px;
        display: flex;
        flex-direction: row !important;
        align-items: center !important;
        gap: 18px;
        justify-content: center;

        svg {
            width: 64px;
            height: 64px;

            @media screen and (width < 811px) {
                width: 55px;
                height: 55px;

            }
        }

        p {
            color: $dark;
            font-family: Cairo;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 70%;
            margin: 0;

            @media screen and (width < 767px) {
                font-size: 15px;
                width: 90%;

            }

            @media screen and (width < 811px) {
                font-size: 14px;
                width: 90%;

            }
        }
    }


}



[dir="ltr"] .app_products_btn {
    margin-left: 50px;

    @media screen and (width < 1285px) {
        margin-left: 50px;
    }
}

[dir="rtl"] .app_products_btn {
    margin-right: 50px;

    @media screen and (width < 1285px) {
        margin-left: 50px;
    }
}

.app_products_btn {

    @media screen and (width < 900px) {
        display: flex;
        justify-content: center;
        align-items: center;

    }
}